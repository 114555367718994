

@-moz-document url-prefix() {
    .piximagerendering {
        image-rendering : crisp-edges;
    }
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
    .piximagerendering {
        image-rendering : pixelated;
    }
}

.pastel {
background-color: white;

}

.imageblink {
  position: relative;

}

.imageblink img {
  position: absolute;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;


}
.imagetop {
	animation-name: fade;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 5;
	animation-duration: 3s;
	animation-direction: alternate;
}

.imagepaintpixel {
	animation-name: fade;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1;
	animation-duration: 1s;
	animation-direction: alternate;
}

@keyframes fade {
	0% {
		opacity: 1;
	}
	25% {
		opacity: 1;
	}
	75% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}



.auth0badge {
	position:absolute;
	bottom:0;
	left:0px;
	margin-left: 5px;
}

.auth0badge img {
	opacity: 0.5;
}


